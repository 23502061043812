import React from 'react';
import AppWrapper from '../styles/council.css';
import withContext from '../store/withContext';

var apiEnv = process.env.NODE_ENV === 'development' ? 'https://localhost:3006' : 'https://api.hirezstudios.com';

class CouncilResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameArr: []
    }
  }
  
  allApplicants = (website) => {
    fetch(`${apiEnv}/council/vote/results2/${website}`, {
      method: 'GET',
      headers: {
        "Content-Type": 'application/json'
      },
    }).then((response) => response.json()).then((response) => {
      this.setState({
        nameArr: response
      })
    })
  };

  sortData = (a, b) => {
    if (a.votes > b.votes) {
      return -1;
    } else if (a.votes < b.votes) {
      return 1;
    } else {
      return 0;
    }
  }


  componentDidMount() {
    if (window.location.hash === "#aoc") {
      this.setState({
        website: 'paladins'
      })
      this.allApplicants('paladins');
    } else {
      this.setState({
        website: 'smite'
      })
      this.allApplicants('smite');
    }
  }

  render() {
    return (
      <AppWrapper>
        <div className="results">
          <h2>Results</h2>
          {this.state.nameArr.length > 0 && this.state.nameArr.sort(this.sortData).map((applicant) => 
            <div className="applicant-votes">
              <p className="name">{applicant._id}: </p>
              <p className="vote">{applicant.votes}</p>
            </div>
          )}
        </div>
      </AppWrapper>
    );
  }
}

export default withContext(CouncilResults);
